import _ from 'lodash';

import { ReactComponent as Battery } from '../../../images/battery.svg';
import { ReactComponent as Charge } from '../../../images/charge.svg';
import { ReactComponent as OilLife } from '../../../images/oil-life.svg';
import { ReactComponent as Fuel } from '../../../images/fuel.svg';
import { ReactComponent as Location } from '../../../images/location.svg';
import { ReactComponent as Odometer } from '../../../images/odometer.svg';
import { ReactComponent as TirePressure } from '../../../images/tire-pressure.svg';

const endpointMap = {
  '/battery': {
    name: 'EV battery level',
    icon: Battery,
  },

  '/charge': {
    name: 'EV charging status',
    icon: Charge,
  },

  '/engine/oil': {
    name: 'Oil life',
    icon: OilLife,
  },

  '/fuel': {
    name: 'Fuel tank level',
    icon: Fuel,
  },

  '/location': {
    name: 'Location',
    icon: Location,
  },

  '/odometer': {
    name: 'Odometer',
    icon: Odometer,
  },

  '/tires/pressure': {
    name: 'Tire pressure',
    icon: TirePressure,
  },
};

const apiResponseCleaner = ({ path, body, headers }) => {
  const cleanedResponseBody = responseCleaner(body, headers);

  const name = _.get(endpointMap, `${path}.name`, '');
  const icon = _.get(endpointMap, `${path}.icon`, '');

  return {
    name,
    icon,
    responseBody: cleanedResponseBody,
  };
};

const responseCleaner = (body = {}, headers = {}) => {
  const unitSystem = _.get(headers, 'sc-unit-system', '');

  return Object.keys(body).map((dataName) => {
    // get the formatted name for the data point
    const formattedDataName = _.upperFirst(_.lowerCase(dataName));

    // get the formatted value
    const formattedDataValue = dataValueCleaner(
      dataName,
      body[dataName],
      unitSystem
    );

    return {
      name: formattedDataName,
      value: formattedDataValue,
    };
  });
};

const formatNumber = (odometer) => {
  return Number(odometer).toFixed(2);
}

const formatState = (state) => {
  return state
    .split("_")
    .join(" ")
    .toLowerCase()
};

const dataValueCleaner = (dataName, dataValue, unitSystem) => {
  switch (dataName) {
    case 'percentRemaining':
    case 'lifeRemaining':
      return `${formatNumber(dataValue) * 100}%`;
    case 'range':
    case 'distance':
      return unitSystem === 'imperial'
        ? `${formatNumber(dataValue)} miles`
        : `${formatNumber(dataValue)} km`;
    case 'amountRemaining':
      return unitSystem === 'imperial' ? `${formatNumber(dataValue)} gal` : `${formatNumber(dataValue)} L`;
    case 'frontLeft':
    case 'frontRight':
    case 'backLeft':
    case 'backRight':
      return unitSystem === 'imperial'
        ? `${formatNumber(dataValue)} psi`
        : `${formatNumber(dataValue)} kPa`;
    case 'isPluggedIn':
      return _.lowerCase(dataValue);
    case 'state':
      return formatState(dataValue);
    default:
      return dataValue;
  }
};

export default apiResponseCleaner;
