import React from 'react';

import { ActionEndpointItem } from './components';

const VehicleActions = ({ actionEndpoints, selectedVehicleId }) => (
  <ul className="wrapper-section">
    {actionEndpoints.map((actionEndpoint) => (
      <ActionEndpointItem
        key={actionEndpoint.name}
        actionEndpoint={actionEndpoint}
        selectedVehicleId={selectedVehicleId}
      />
    ))}
  </ul>
);

export default VehicleActions;
