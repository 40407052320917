
const formatGetVehicles = (data) => {
  const result = data.reduce((acc, vehicle) => {
    const vehicleInfo = vehicle['/'].body;
    const vehicleVIN = vehicle['/vin'].body;
    return {
      ...acc,
      [vehicleInfo.id]: {
        ...vehicleInfo,
        ...vehicleVIN,
      },
    };
  }, {});

  return result;
}

const formatBatch = (data) => {
  return Object.keys(data.data).map((key) => {
    const el = data.data[key];
    return { path: key, ...el };
  });
}

export default {
  formatGetVehicles,
  formatBatch
}
