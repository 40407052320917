import React, { useState } from 'react';

import { ClipLoader } from 'react-spinners';

import filterErrors from '../../utils/filterApiErrors';
import { ErrorCard } from '../../../../components';
import { EndpointItem, RefreshButton } from './components';

const VehicleData = ({ vehiclesData, setVehiclesData, vehicleId }) => {
  const [isFetching, setIsFetching] = useState(false);

  const vehicleData = vehiclesData[vehicleId];

  const errorMessage = vehicleData.responses.error;
  const responsesWithoutErrors = filterErrors(vehicleData.responses);

  return (
    <div className="wrapper-section">
      <RefreshButton
        setIsFetching={setIsFetching}
        vehicleId={vehicleId}
        setVehiclesData={setVehiclesData}
        vehiclesData={vehiclesData}
      />
      {isFetching &&
        (
          <ClipLoader
            css={{ display: 'block', margin: '0 auto' }}
            color="#00819D"
          />
        )}
      {!isFetching && !errorMessage && (
        <ul>
          {responsesWithoutErrors.map((response) => (
            <EndpointItem key={response.path} response={response} />
          ))}
        </ul>
      )}
      {!isFetching && errorMessage && <ErrorCard />}
    </div>
  );
};

export default VehicleData;

VehicleData.defaultProps = {
  vehiclesData: [],
  vehicleId: '',
  setVehiclesData: () => { },
};
