import React, { useState } from 'react';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import { Button } from '../../../../../../components';

const ActionEndpointItem = ({ actionEndpoint, selectedVehicleId }) => {
  const [isActing, setIsActing] = useState(false);

  const { name, Icon, actions } = actionEndpoint;

  return (
    <li key={name} className="list-item flex">
      <div className="flex align-center sm-2">
        <Icon title={name} className="p-r-med" />
        <span className="ibm-plex-sans-med">{name}</span>
      </div>
      {actions.map(({ name, onClickHandler }) => (
        <Button
          key={name}
          text={name}
          disabled={isActing}
          additionalClassNames="m-l-med button-primary fixed"
          onClickHandler={() => {
            reportToSegment(types.TRACK, eventNames.buttonClicked, { label: 'action', text: name });
            setIsActing(true);
            onClickHandler(selectedVehicleId).then(() => {
              setIsActing(false);
            });
          }}
        />
      ))}
    </li>
  );
};

export default ActionEndpointItem;
