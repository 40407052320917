import React, { useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { PrivateRoute, Nav } from './components';
import { Connect, Dashboard } from './scenes';

import './scss/base.scss';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const query = useQuery();
  const isSalesDemo = query.get('sales') === 'true';

  return (
    <>
      <Nav
        isAuthenticated={isAuthenticated}
        setIsAuthenticated={setIsAuthenticated}
      />
      <Switch>
        <PrivateRoute isAuthenticated={isAuthenticated} path="/dashboard">
          <Dashboard />
        </PrivateRoute>
        <Route
          path="/"
          children={({ history }) => (
            <Connect
              setIsAuthenticated={setIsAuthenticated}
              history={history}
              isSalesDemo={isSalesDemo}
            />
          )}
        />
      </Switch>
    </>
  );
};

export default App;
