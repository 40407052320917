import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ children, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={() =>
      isAuthenticated ? children : <Redirect to={{ pathname: '/' }} />
    }
  />
);

export default PrivateRoute;
