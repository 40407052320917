import React from 'react';
import { withRouter } from 'react-router-dom';
import Smartcar from '@smartcar/auth';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import api from '../../utils/api';

import { FlashError, Button, Header } from '../../components';

const staticText = {
  title: 'Smartcar demo app',
  body: [
    "Welcome to the Smartcar demo application. This app walks you through the steps of using Smartcar's API.",
  ],
  buttonHeading:
    'Click "Connect your car" to launch Smartcar Connect and authorize a vehicle. Once your car is linked to the demo app, you can make API requests to locate, unlock, read the odometer, and retrieve other information from it.',
  buttonText: 'Connect your car',
  testButtonHeading:
    "Don't have access to a car? Use Smartcar's test mode to connect to a simulated vehicle.",
  buttonTestModeText: 'Connect to a test car',
};

class Connect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
    };

    this.openConnect = this.openConnect.bind(this);
    this.smartcarAuthOnComplete = this.smartcarAuthOnComplete.bind(this);

    // NOTE: Keep the constructor instantiation *after* the call to bind the callback function:
    //     this.smartcarAuthOnComplete = this.smartcarAuthOnComplete.bind(this);
    //
    // If it is ordered in the other direction, the function passed into the Smartcar constructor
    // will not be bound to the component and will not be able to manipulate state.
    this.smartcar = new Smartcar({
      clientId: process.env.REACT_APP_CLIENT_ID,
      redirectUri: process.env.REACT_APP_REDIRECT_URL,
      scope: [
        'read_vehicle_info',
        'read_vin',
        'read_battery',
        'read_charge',
        'read_engine_oil',
        'read_fuel',
        'read_location',
        'read_odometer',
        'read_tires',
        'control_security',
      ],
      onComplete: this.smartcarAuthOnComplete,
    });

  }

  openConnect(testMode) {
    const testType = this.props.isSalesDemo ? 'demo' : 'test';
    this.smartcar.mode = testMode ? testType : 'live';

    const text = testMode
      ? staticText.buttonTestModeText
      : staticText.buttonText;
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'connect action',
      text,
    });

    this.smartcar.openDialog({ forcePrompt: true });
  }

  // TODO: proper error handling
  smartcarAuthOnComplete(err, code) {
    if (err) {
      this.setState({
        error: err.name,
      });
    } else if (code) {
      return api.exchangeCode(code).then((res) => {
        // TODO: Add error handling.
        this.props.setIsAuthenticated(true);
        this.props.history.push('/dashboard');
        reportToSegment(types.PAGE);
      });
    }
  }

  render() {
    return (
      <div className="wrapper-page-small">
        {this.state.error && <FlashError err={this.state.error} />}
        <Header title={staticText.title} body={staticText.body} />
        <div className="m-b-lg">
          <p className="m-b-med">{staticText.buttonHeading}</p>
          <Button
            text={staticText.buttonText}
            onClickHandler={() => this.openConnect(this.props.isSalesDemo)}
            additionalClassNames="large button-primary width-265"
          />
        </div>
        {!this.props.isSalesDemo && (
          <div>
            <p className="m-b-med">{staticText.testButtonHeading}</p>
            <Button
              text={staticText.buttonTestModeText}
              onClickHandler={() => this.openConnect(true)}
              additionalClassNames="large button-secondary width-265"
            />
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(Connect);
