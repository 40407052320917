import React from 'react';

import { ReactComponent as Alert } from '../../images/alert-triangle.svg';

import errors from '../../utils/errors';

const FlashError = ({ err }) => (
  <div className="flex align-center border-red p-sm alert wrapper-section">
    <span className="m-r-sm">
      <Alert className="block" />
    </span>
    <span>{errors(err)}</span>
  </div>
);

export default FlashError;
