import React, { useState } from 'react';
import _ from 'lodash';

import { ClipLoader } from 'react-spinners';
import { Header } from '../../components';
import {
  DisconnectButton,
  VehicleActions,
  VehicleData,
  VehicleSelector,
  VehicleStaticInfo,
} from './components';

import actionEndpoints from './utils/vehicleActionsConfig';
import api from '../../utils/api';

const staticText = {
  title: 'Make a request',
  body: [
    'Congrats! You have successfully connected your vehicle(s) to the Smartcar demo app. You can now make API requests to locate, unlock, read the odometer, and more.',
  ],
};

const Dashboard = () => {
  const [vehiclesData, setVehiclesData] = useState({});
  const [selectedVehicleId, setSelectedVehicleId] = useState('');
  const [isFetchingVehicles, setIsFetchingVehicles] = useState(false);
  const [isFetchingBatch, setIsFetchingBatch] = useState(false);

  // If there are no vehicles, fetch.
  if (_.isEmpty(vehiclesData)) {
    if (!isFetchingVehicles) {
      setIsFetchingVehicles(true);
      api.getVehicles().then((data) => {
        setVehiclesData(data);
        // Set the selectedVehicleId to the first vehicle.
        setSelectedVehicleId(Object.keys(data)[0]);
        setIsFetchingVehicles(false);
      });
    }
  }

  // If the current vehicle does not have data, fetch.
  if (
    selectedVehicleId &&
    _.isEmpty(vehiclesData[selectedVehicleId].responses)
  ) {
    if (!isFetchingBatch) {
      setIsFetchingBatch(true);
      api
        .sendBatch(selectedVehicleId)
        .catch((error) => ({ error }))
        .then((data) => {
          const updatedVehicle = vehiclesData[selectedVehicleId];
          updatedVehicle.responses = data;
          setVehiclesData({
            ...vehiclesData,
            [selectedVehicleId]: updatedVehicle,
          });
          setIsFetchingBatch(false);
        });
    }
  }

  return (
    <div className="wrapper-page-large">
      <Header title={staticText.title} body={staticText.body} />
      {!isFetchingVehicles && (
        <VehicleSelector
          vehiclesData={vehiclesData}
          selectedVehicleId={selectedVehicleId}
          setSelectedVehicleId={setSelectedVehicleId}
        />
      )}
      {selectedVehicleId && vehiclesData[selectedVehicleId].vin && (
        <>
          <VehicleStaticInfo vin={vehiclesData[selectedVehicleId].vin} />
          <VehicleActions
            actionEndpoints={actionEndpoints}
            selectedVehicleId={selectedVehicleId}
          />
        </>
      )}
      {!_.isEmpty(vehiclesData[selectedVehicleId]) && !isFetchingBatch ? (
        <>
          <VehicleData
            vehiclesData={vehiclesData}
            setVehiclesData={setVehiclesData}
            vehicleId={selectedVehicleId}
          />
          <DisconnectButton
            vehicleId={selectedVehicleId}
            vehiclesData={vehiclesData}
            setVehiclesData={setVehiclesData}
            setSelectedVehicleId={setSelectedVehicleId}
          />
        </>
      ) : (
        <ClipLoader
          css={{ display: 'block', margin: '0 auto' }}
          color="#00819D"
        />
      )}
    </div>
  );
};

export default Dashboard;
