import React from 'react';

import apiResponseCleaner from '../../../../utils/apiResponseCleaner';
import GoogleMap from '../../../../../../components/GoogleMap/GoogleMap';

const EndpointItem = ({ response }) => {
  const { name, icon, responseBody } = apiResponseCleaner(response);

  const responseDataFactory = (data) => {
    return data.map((datum) => (
      <li key={datum.name} className="list-item list-item-m-sm">
        <span>{`${datum.name}: `}</span>
        <span>{datum.value}</span>
      </li>
    ));
  };

  const iconGenerator = (Icon) => <Icon title={name} className="p-r-med" />;

  return (
    <li className="list-item flex flex-start border-bottom p-t-med p-b-med">
      <div className="flex align-center sm-3">
        {/* <input type="checkbox" /> */}
        {iconGenerator(icon)}
        <span className="ibm-plex-sans-med">{name}</span>
      </div>
      <ul className="sm-5">{responseDataFactory(responseBody)}</ul>
      {name === 'Location' && <div className="sm-4"><GoogleMap location={{ latitude: responseBody[0].value, longitude: responseBody[1].value }} /></div>}
    </li>
  );
};

export default EndpointItem;
