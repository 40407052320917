import React from 'react';

import './ErrorCard.scss'

const ErrorMessage = () => (
  <div className="card m-t-med">
    <p>There was an error on our end, please click the <b>Refresh</b> link above, if it persists please let us know.</p>
  </div>
);

export default ErrorMessage;
