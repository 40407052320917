import React from 'react';
import { useHistory } from 'react-router-dom';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import { ReactComponent as SmartcarLogo } from '../../images/smartcar-logo.svg';

const staticText = {
  button: 'Log out',
  href: 'https://smartcar.com',
}

const Nav = ({ isAuthenticated, setIsAuthenticated }) => {
  const history = useHistory();

  const logout = () => {
    reportToSegment(types.TRACK, eventNames.buttonClicked, { label: 'action', text: staticText.button });
    history.push('/');
    setIsAuthenticated(false);
  };

  const home = () => {
    reportToSegment(types.TRACK, eventNames.linkClicked, {
      section: 'header',
      style: 'image',
      label: 'logo',
      text: '',
      path: staticText.href,
    });
  };

  return (
    <div className="flex space-between align-center p-l-lg p-r-lg p-b-med p-t-med border-bottom">
      <a href={staticText.href} onClick={home}>
        <SmartcarLogo title="Smartcar" className="block" />
      </a>
      {isAuthenticated && (
        <button className="button-unstyled" onClick={logout}>
          {staticText.button}
        </button>
      )}
    </div>
  );
};

export default Nav;
