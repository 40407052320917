export default (err) => {
  switch (err) {
    case 'AccessDenied':
      return 'Looks like you denied Smartcar Demo access to your vehicle(s). To continue with using Smartcar Demo, please allow access by connecting your vehicle(s) below.';
    case 'InvalidSubscription':
      return 'Please update the connected services subscription on your vehicle(s) before trying to connect again.';
    case 'VehicleIncompatible':
      return 'Unfortunately your vehicle is not compatible with Smartcar Demo.';
    default:
      return 'There was an error connecting your vehicle. Please try again!';
  }
};
