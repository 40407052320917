import React from 'react';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import { ReactComponent as Refresh } from '../../../../../../images/refresh.svg';

import api from '../../../../../../utils/api';
import { Button } from '../../../../../../components';

const staticText = {
  button: 'Refresh',
};

const RefreshButton = ({
  vehicleId,
  setIsFetching,
  setVehiclesData,
  vehiclesData,
}) => {
  const callApi = (event) => {
    reportToSegment(types.TRACK, eventNames.buttonClicked, { label: 'action', text: staticText.button });
    setIsFetching(true);
    api.sendBatch(vehicleId)
    .catch(error=> ({error}))
    .then((data) => {
      const vehicleData = vehiclesData[vehicleId];
      vehicleData.responses = data;
      setVehiclesData({
        ...vehiclesData,
        [vehicleId]: vehicleData,
      });
      setIsFetching(false);
    });
  };

  return (
    <div className="flex border-bottom p-b-med">
      <Refresh />
      <Button
        onClickHandler={callApi}
        additionalClassNames="m-l-xs button-unstyled"
        text={staticText.button}
      />
    </div>
  );
};

export default RefreshButton;
