import React from 'react';

const Header = ({ title, body }) => (
  <div className="wrapper-section">
    <h1 className="m-b-lg">{title}</h1>
    {body.map((p, i) => (
      <p key={`${title}-${i}`} className="m-b-med">
        {p}
      </p>
    ))}
  </div>
);

export default Header;
