import React from 'react';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import { Button } from '../../../../components';

import api from '../../../../utils/api';

const staticText = {
  button: 'Disconnect',
};

const DisconnectButton = ({
  vehicleId,
  vehiclesData,
  setVehiclesData,
  setSelectedVehicleId,
}) => {
  const callApi = () => {
    reportToSegment(types.TRACK, eventNames.buttonClicked, { label: 'action', text: staticText.button });
    api.sendDisconnect(vehicleId).then(() => {
      // NOTE: Order is important.
      // - first delete the vehicle from state
      // - second update selected vehicle id
      // - third update vehicles Data
      delete vehiclesData[vehicleId];
      setSelectedVehicleId(Object.keys(vehiclesData)[0]);
      setVehiclesData(vehiclesData);
    });
  };
  return (
    <Button
      text={staticText.button}
      additionalClassNames="button-unstyled"
      onClickHandler={callApi}
    />
  );
};

export default DisconnectButton;
