import React from 'react';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

const VehicleSelector = ({
  vehiclesData,
  selectedVehicleId,
  setSelectedVehicleId,
}) => {

  const getVehicleMMY = (vehicleId) => {
    const { make, model, year } = vehiclesData[vehicleId];
    return `${year} ${make} ${model}`;
  };

  const handleChange = (event) => {
    const vehicleId = event.target.value;
    const text = getVehicleMMY(vehicleId);
    reportToSegment(types.TRACK, eventNames.dropdownClosed, { label: 'select', text });
    setSelectedVehicleId(vehicleId);
  };

  const options = Object.keys(vehiclesData).map((vehicleId) => {
    const name = getVehicleMMY(vehicleId);

    return name ? (
      <option key={name} value={vehicleId}>
        {name}
      </option>
    ) : null;
  });

  return (
    <select
      value={selectedVehicleId}
      className="wrapper-section"
      onChange={handleChange}
    >
      {options}
    </select>
  );
};

export default VehicleSelector;

VehicleSelector.defaultProps = {
  vehiclesData: [],
  selectedVehicleId: '',
  setSelectedVehicleId: () => {},
};
