import React from 'react';
import GoogleMapReact from 'google-map-react';

import Marker from './components/Marker';
import styles from './google-map-styles';

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const mapOptions = {
  fullscreenControl: false,
  zoomControl: false,
  clickableIcons: false,
  styles,
}

const GoogleMap = ({ location }) => {
  const defaultProps = {
    center: {
      lat: location.latitude,
      lng: location.longitude
    },
    zoom: 13
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '300px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: API_KEY }}
        center={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={mapOptions}
        draggable={true}
      >
        <Marker
          lat={location.latitude}
          lng={location.longitude}
        />
      </GoogleMapReact>
    </div>
  );
}

export default GoogleMap;
