const filterErrors = (data) => {
  if (data.error) {
    return [];
  }
  return data.filter(({ body }) => {
    return !body.error;
  });
};

export default filterErrors;
