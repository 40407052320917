import React from 'react';

import { ReactComponent as Hash } from '../../../../images/hash.svg';

const staticText = {
  vin: 'VIN',
};

const VehicleStaticInfo = ({ vin }) => (
  <div className="m-b-med flex align-center">
    <div className="flex align-center sm-2">
      <Hash title={staticText.vin} className="m-r-med" />
      <span className="ibm-plex-sans-med">{staticText.vin}</span>
    </div>
    <span className="m-l-med">{vin}</span>
  </div>
);

export default VehicleStaticInfo;

VehicleStaticInfo.defaultProps = {
  vin: '',
};
