import React from 'react';
import { number } from 'prop-types';

import './Marker.scss';

// the way google-map-react works, Markers must be passed lat/lng props but need not use them
// eslint-disable-next-line no-unused-vars
const Marker = ({ lat, lng }) => (
  <div className="map-marker">
    <img alt="Vehicle marker" src="images/marker.svg" />
  </div>
);

export default Marker;

Marker.propTypes = {
  lat: number.isRequired,
  lng: number.isRequired,
};
