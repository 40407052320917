import { ReactComponent as Lock } from '../../../images/security.svg';

import api from '../../../utils/api';

const endpoints = [
  {
    name: 'Lock/unlock',
    Icon: Lock,
    actions: [
      {
        name: 'Lock doors',
        onClickHandler: (vehicleId) => {
          return api.sendLock(vehicleId);
        },
      },
      {
        name: 'Unlock doors',
        onClickHandler: (vehicleId) => {
          return api.sendUnlock(vehicleId);
        },
      },
    ],
  },
];

export default endpoints;
