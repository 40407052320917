import axios from 'axios';
import * as P from 'blend-promise-utils';

import formatter from './apiResponseFormatter';

let api = {};

const constructBody = (vehicleId) => {
  return {
    vehicleId,
  };
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  // Allows cookies to be sent.
  withCredentials: true,
});

api.axiosInstance = instance;

const endpoints = [
  '/battery',
  '/charge',
  '/engine/oil',
  '/fuel',
  '/location',
  '/odometer',
  '/tires/pressure',
];

const retry = (fn) => {
  return P.retry(
    fn,
    {
      maxAttempts: 3, delayMs: 500, isRetryable: (req) => req.response.status === 500
    }
  )();
}

api.sendBatch = async (vehicleId) => {
  const { data } = await retry(
    () => instance.post('/batch', { vehicleId, endpoints })
  );

  return formatter.formatBatch(data);
};

api.sendLogout = async () => {
  return await retry(() => instance.get('/logout'));
};

api.sendLock = async (vehicleId) => {
  return await retry(() => instance.post('/lock', constructBody(vehicleId)));
};

api.sendUnlock = async (vehicleId) => {
  return await retry(() => instance.post('/unlock', constructBody(vehicleId)));
};

api.sendDisconnect = async (vehicleId) => {
  return await retry(() => instance.post('/disconnect', constructBody(vehicleId)));
};

api.exchangeCode = async (code) => {
  return await retry(() => instance.get(`/exchange?code=${code}`));
};

api.getVehicles = async () => {
  const { data } = await retry(() => instance.get('/vehicles'));

  return formatter.formatGetVehicles(data);
};

export default api;
