import React from 'react';

import { ClipLoader } from 'react-spinners';

const Button = ({ text, disabled, onClickHandler, additionalClassNames }) => (
  <button
    className={`button${
      additionalClassNames ? ` ${additionalClassNames}` : ''
    }`}
    onClick={onClickHandler}
    disabled={disabled}
  >
    {disabled ? <ClipLoader color="#fff" size={24} /> : <span>{text}</span>}
  </button>
);

export default Button;
